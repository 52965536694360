var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',{staticClass:"title font-weight-medium primary--text"},[_vm._v(" "+_vm._s('Reporte inmediato de incidente y/o falla de control')+" ")]),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s('HSEQ-S&SO1-F-9')}})]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto","md":"6"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('validate-activity',{attrs:{"module":"incidencia","page":"listaIncidencias","activity":"asignarRevision"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isDisabled = ref.isDisabled;
return [_c('v-btn',{staticClass:"ma-1 text-none",attrs:{"disabled":isDisabled,"color":"primary","rounded":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-cog")]),_vm._v(" Asignar responsable ")],1)]}}])}),_c('validate-activity',{attrs:{"module":"incidencia","page":"crearIncidencia","activity":"crearIncidencia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isDisabled = ref.isDisabled;
return [_c('v-btn',{staticClass:"ma-1 text-none",attrs:{"disabled":isDisabled,"to":{
								name: 'IncidentCreate',
							},"color":"primary","rounded":"","link":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Nuevo registro ")],1)]}}])})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('date-picker',{attrs:{"range":"","format":"DD-MM-YYYY","placeholder":"Seleccionar rango de fechas"},on:{"input":function (val) {
							_vm.listenDatePicker(val);
						}},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.incident_types,"label":"Seleccionar incidentes","item-text":"label","item-value":"value","single-line":"","dense":"","hide-details":"","solo":""},on:{"input":_vm.changeFilter},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.control_failure,"label":"Seleccionar falla de control","item-text":"label","item-value":"value","single-line":"","dense":"","hide-details":"","solo":""},on:{"input":_vm.changeFilter},model:{value:(_vm.filters.fail),callback:function ($$v) {_vm.$set(_vm.filters, "fail", $$v)},expression:"filters.fail"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.registerStatus,"label":"Todos los estados","item-text":"label","item-value":"value","single-line":"","dense":"","hide-details":"","solo":""},on:{"input":_vm.changeFilter},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.incidents,"loading":_vm.loading,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
						var item = ref.item;
return [_c('span',{staticClass:"grey--text text-no-wrap",domProps:{"textContent":_vm._s(_vm.formatDateString(item.date))}})]}},{key:"item.service",fn:function(ref){
						var item = ref.item;
return [(item.service)?_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(((item.service.costCenter) + " - " + (item.service.name)))+" ")]):_vm._e()]}},{key:"item.control_failure",fn:function(ref){
						var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center flex-wrap"},_vm._l((_vm.parseIncidents(
										item.control_failure
									)),function(incident,i){return _c('v-chip',{key:i,staticClass:"ma-1 blue--text",attrs:{"color":"blue lighten-4","small":""}},[_vm._v(" "+_vm._s(incident)+" ")])}),1)]}},{key:"item.incidents",fn:function(ref){
									var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center flex-wrap"},_vm._l((_vm.parseIncidents(
										item.incidents
									)),function(incident,i){return _c('v-chip',{key:i,staticClass:"ma-1 blue--text",attrs:{"color":"blue lighten-4","small":""}},[_vm._v(" "+_vm._s(incident)+" ")])}),1)]}},{key:"item.status",fn:function(ref){
									var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","color":_vm.getStatusRegister(item.status).color}},[_vm._v(" "+_vm._s(_vm.getStatusRegister(item.status).label)+" ")])]}},{key:"item.show",fn:function(ref){
									var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('validate-activity',{attrs:{"module":"incidencia","page":"aprobarIncidencia","activity":"aprobarIncidencia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var isDisabled = ref.isDisabled;
return [(item.status == 'PENDING')?_c('v-btn',{staticClass:"text-none",attrs:{"disabled":isDisabled,"to":{
											name: 'IncidentRevision',
											params: {
												incidentId: item.id,
											},
										},"color":"primary","text":"","link":""}},[_vm._v(" Revisar ")]):_vm._e()]}}],null,true)}),_c('validate-activity',{attrs:{"module":"incidencia","page":"crearIncidencia","activity":"crearIncidencia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var isDisabled = ref.isDisabled;
return [(item.status == 'DRAFT')?_c('v-btn',{staticClass:"text-none",attrs:{"disabled":isDisabled,"to":{
											name: 'IncidentDraft',
											params: {
												incidentId: item.id,
											},
										},"color":"primary","text":"","link":""}},[_vm._v(" Editar ")]):_vm._e()]}}],null,true)}),_c('validate-activity',{attrs:{"module":"incidencia","page":"mostrarIncidencia","activity":"mostrarIncidencia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var isDisabled = ref.isDisabled;
return [_c('v-btn',{staticClass:"text-none",attrs:{"disabled":isDisabled,"to":{
											name: 'IncidentShow',
											params: {
												incidentId: item.id,
											},
										},"color":"primary","text":"","link":""}},[_vm._v(" Ver "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-right ")])],1)]}}],null,true)})],1)]}}],null,true)})],1),_c('div',{staticClass:"text-center py-2"},[(_vm.registers_pagination)?_c('v-pagination',{attrs:{"length":_vm.registers_pagination.pages || 0},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}}):_vm._e()],1)],1)],1),_c('incident-assign',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }