import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import { registerStatus, isDraft, getStatus } from '@/store/services/register-status.js';
import moment from 'moment';
import IncidentAssign from '@/components/incident/bodies/asign/IncidentAssign.vue';

export default {
	name: 'IncidentList',
	data: () => ({
		title: '',
		search: '',
		headers: [],
		dateRange: {},
		loading: false,
		registerStatus: [
			...[{ label: 'Todos los estados', value: '' }],
			...registerStatus,
		],
		filters: {
			status: '',
			q: '',
			start_date: null,
			end_date: null,
			pagination: 1,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			page: 1,
			type: null,
			fail: null,
		},

		dialog: false,
		isLoadingAssign: false,
		isLoadingAssigned: false,
		errorAssign: [],
	}),
	computed: {
		...mapState('register', ['registers']),
		...mapState('incident', ['incidents', 'registers_pagination']),

		incident_types() {
			const incident_types = this.$store.state.incident.incident_types;
			return (
				[{ label: 'Todos los incidentes', value: null }, ...incident_types] || []
			);
		},
		control_failure() {
			const control_failure = this.$store.state.incident.control_failure;
			return (
				[
					{ label: 'Todas las fallas de control', value: null },
					...control_failure,
				] || []
			);
		},
		inspection() {
			return this.$store.state.register.registers_inspection || null;
		},
		inspection_code() {
			return this.$route?.params?.id || null;
		},
		breadcrumbs() {
			return [
				{
					text: 'Reporte inmediato de incidente y/o falla de control',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentList',
					},
				},
				{
					text: 'Lista',
					disabled: true,
					href: '/',
				},
			];
		},
	},
	created() {
		this.getIncident();
		this.setHeaders();
		$EventBus.$emit('showSubNav', false);
	},
	methods: {
		...mapActions('register', ['list', 'cleanRegisters']),
		...mapActions('incident', ['listIncident', 'getIncidentTypes', 'cleanIncidents']),

		async getData() {
			this.loading = true;
			let filters = { ...this.filters };
			if ((filters?.q || '').length < 2) {
				delete filters.q;
			}
			await this.listIncident(filters);
			this.loading = false;
		},
		async getIncident() {
			this.loading = true;
			await this.listIncident(this.filters);
			this.getIncidentTypes();
			this.loading = false;
		},
		listenDatePicker(val) {
			this.filters.start_date = val[0] ? moment(val[0]).format('YYYY-MM-DD') : '';
			this.filters.end_date = val[1] ? moment(val[1]).format('YYYY-MM-DD') : '';
			this.filters.page = 1;
			this.getData();
		},
		formatDateString(date) {
			let dateString = '';
			if (date) {
				let d = date.toString().substring(0, 10).split('-');
				dateString = `${d[2]}-${d[1]}-${d[0]}`;
			}
			return dateString;
		},
		changeFilter() {
			this.filters.page = 1;
			this.getData();
		},
		setHeaders() {
			this.headers = [
				{
					text: 'Fecha',
					value: 'date',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Servicio',
					value: 'service',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Falla de control',
					value: 'control_failure',
					sortable: false,
					align: 'center',
					class: 'primary--text text-center',
				},
				{
					text: 'Incidentes',
					value: 'incidents',
					sortable: false,
					align: 'center',
					class: 'primary--text text-center',
				},
				// {
				// 	text: 'Área/Taller/Estación',
				// 	value: 'area_workshop_station',
				// 	align: 'null',
				// 	sortable: false,
				// 	class: 'primary--text text-center',
				// },
				// {
				// 	text: 'Criterios aplicados',
				// 	value: 'itemsTotal',
				// 	align: 'center',
				// 	sortable: false,
				// 	class: 'primary--text',
				// },
				// {
				// 	text: 'Cumplimiento',
				// 	value: 'itemsSi',
				// 	align: 'center',
				// 	sortable: false,
				// 	class: 'primary--text',
				// },
				// {
				// 	text: 'Incumplimiento',
				// 	value: 'itemsNo',
				// 	align: 'center',
				// 	sortable: false,
				// 	class: 'primary--text',
				// },
				// {
				// 	text: 'N/A',
				// 	value: 'itemsNa',
				// 	align: 'center',
				// 	sortable: false,
				// 	class: 'primary--text',
				// },
				{
					text: 'Estado',
					value: 'status',
					align: 'center',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'Acciones',
					value: 'show',
					align: 'right',
					sortable: false,
					class: 'primary--text',
				},
			];
		},
		valIsDraft(value) {
			return isDraft(value);
		},
		getStatusRegister(value) {
			return getStatus(value);
		},
		listenTextSearch() {
			if ((this.filters.q || '').length > 1 || this.filters.q == '') {
				this.filters.page = 1;
				this.getData();
			}
		},
		parseIncidents(data) {
			let response = [];
			if (data) {
				try {
					const parsed = JSON.parse(data);
					response = parsed.values;
				} catch (error) {}
			}
			return response;
		},
	},
	destroyed() {
		this.cleanIncidents();
	},
	components: {
		IncidentAssign,
	},
};
