// AREAS MANAGER
const registerStatus = [
  { label: "Aprobado", value:"APPROVED", color: "green" },
  { label: "Pendiente", value:"PENDING", color: "red" },
  { label: "Borrador", value:"DRAFT", color: "warning" }
];

const getStatus = (value) => {
  let statusObj = {};
  registerStatus.forEach(status => {
    if(value == status.value){
      statusObj = status;
    }
  })
  return statusObj;
}

const isDraft = (value) => {
  return (value === "DRAFT");
}

const isPending = (value) => {
	return (value === "PENDING");
}

export { registerStatus, isDraft, getStatus, isPending };