import { mapState, mapActions } from 'vuex';
import { $EventBus } from '@/main';
import ValidationAlert from '@/components/global/ValidationAlert.vue';

export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			asignations: [],
			loadingData: false,
			isLoadingAssign: false,
			errorAssign: [],
		};
	},
	created() {},
	mounted() {},
	computed: {
		...mapState('security', ['usersActive']),
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.getUsersActive();
				this.listAssign();
			}
		},
	},
	methods: {
		...mapActions('security', ['getUsersActive']),
		...mapActions('incident', ['getIncidentAssign', 'saveIncidentAssign']),
		async listAssign() {
			this.loadingData = true;
			const { ok } = await this.getIncidentAssign();
			if (ok) {
				let fomratedArr = [];
				const incidentAssign = this.$store.state.incident.IncidentAssign || [];
				if (Array.isArray(incidentAssign)) {
					incidentAssign.forEach((element) => {
						fomratedArr.push(parseInt(element.user_id));
					});
				}
				this.asignations = fomratedArr;
			}
			this.loadingData = false;
		},

		async save() {
			let asignations = [];
			this.isLoadingAssign = true;
			if (Array.isArray(this.asignations)) {
				asignations = this.asignations.map((id) => {
					return { user_id: id };
				});
			}
			const { ok, error } = await this.saveIncidentAssign({ asignations });
			if (ok) {
				this.dialog = false;
				$EventBus.$emit(
					'showSnack',
					'success',
					'Asigación realizada satisfactoriamente.'
				);
			} else {
				if (Array.isArray(error?.data?.message)) {
					this.errorAssign = error.data.message;
				} else {
					this.errorAssign = ['Algo salio mal, no se guardo la información'];
				}
				$EventBus.$emit(
					'showSnack',
					'error',
					'Algo salio mal, no se guardo la información'
				);
			}
			this.isLoadingAssign = false;
		},
	},
	components: {
		ValidationAlert,
	},
};
